import { jsx as _jsx } from "react/jsx-runtime";
// components
import React from 'react';
import { IC_AI, IC_audio, IC_Document, IC_Excel, IC_File, IC_folder, IC_img, IC_txt, IC_zip, } from '../../assets/icons/files';
// ----------------------------------------------------------------------
// Define more types here
var FORMAT_PDF = ['pdf'];
var FORMAT_TEXT = ['txt'];
var FORMAT_PHOTOSHOP = ['psd'];
var FORMAT_WORD = ['doc', 'docx'];
var FORMAT_EXCEL = ['xls', 'xlsx', 'numbers', 'csv'];
var FORMAT_ZIP = ['zip', 'rar', 'iso'];
var FORMAT_ILLUSTRATOR = ['ai', 'esp'];
var FORMAT_POWERPOINT = ['ppt', 'pptx'];
var FORMAT_AUDIO = ['wav', 'aif', 'mp3', 'aac'];
var FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg'];
var FORMAT_VIDEO = ['m4v', 'avi', 'mpg', 'mp4', 'webm'];
var icon = function (name) { return _jsx("img", { src: name, alt: name }); };
var ICONS = {
    ai: icon(IC_AI),
    audio: icon(IC_audio),
    document: icon(IC_Document),
    excel: icon(IC_Excel),
    file: icon(IC_File),
    folder: icon(IC_folder),
    image: icon(IC_img),
    txt: icon(IC_txt),
    zip: icon(IC_zip),
};
// ----------------------------------------------------------------------
export function fileFormat(fileUrl) {
    var format;
    switch (fileUrl === null || fileUrl === void 0 ? void 0 : fileUrl.includes(fileTypeByUrl(fileUrl))) {
        case FORMAT_TEXT.includes(fileTypeByUrl(fileUrl)):
            format = 'txt';
            break;
        case FORMAT_ZIP.includes(fileTypeByUrl(fileUrl)):
            format = 'zip';
            break;
        case FORMAT_AUDIO.includes(fileTypeByUrl(fileUrl)):
            format = 'audio';
            break;
        case FORMAT_IMG.includes(fileTypeByUrl(fileUrl)):
            format = 'image';
            break;
        case FORMAT_VIDEO.includes(fileTypeByUrl(fileUrl)):
            format = 'video';
            break;
        case FORMAT_WORD.includes(fileTypeByUrl(fileUrl)):
            format = 'word';
            break;
        case FORMAT_EXCEL.includes(fileTypeByUrl(fileUrl)):
            format = 'excel';
            break;
        case FORMAT_POWERPOINT.includes(fileTypeByUrl(fileUrl)):
            format = 'powerpoint';
            break;
        case FORMAT_PDF.includes(fileTypeByUrl(fileUrl)):
            format = 'pdf';
            break;
        case FORMAT_PHOTOSHOP.includes(fileTypeByUrl(fileUrl)):
            format = 'photoshop';
            break;
        case FORMAT_ILLUSTRATOR.includes(fileTypeByUrl(fileUrl)):
            format = 'illustrator';
            break;
        default:
            format = fileTypeByUrl(fileUrl);
    }
    return format;
}
// ----------------------------------------------------------------------
export function fileThumb(fileUrl) {
    var thumb;
    switch (fileFormat(fileUrl)) {
        case 'folder':
            thumb = ICONS.folder;
            break;
        case 'txt':
            thumb = ICONS.txt;
            break;
        case 'zip':
            thumb = ICONS.zip;
            break;
        case 'audio':
            thumb = ICONS.audio;
            break;
        case 'video':
            thumb = ICONS.image;
            break;
        case 'word':
            thumb = ICONS.document;
            break;
        case 'excel':
            thumb = ICONS.excel;
            break;
        case 'powerpoint':
            thumb = ICONS.document;
            break;
        case 'pdf':
            thumb = ICONS.document;
            break;
        case 'photoshop':
            thumb = ICONS.document;
            break;
        case 'illustrator':
            thumb = ICONS.document;
            break;
        case 'image':
            thumb = ICONS.image;
            break;
        default:
            thumb = ICONS.image;
    }
    return thumb;
}
// ----------------------------------------------------------------------
export function fileTypeByUrl(fileUrl) {
    if (fileUrl === void 0) { fileUrl = ''; }
    return (fileUrl && fileUrl.split('.').pop()) || '';
}
// ----------------------------------------------------------------------
export function fileNameByUrl(fileUrl) {
    return fileUrl.split('/').pop();
}
// ----------------------------------------------------------------------
export function fileData(file) {
    // Url
    if (typeof file === 'string') {
        return {
            key: file,
            preview: file,
            name: fileNameByUrl(file),
            type: fileTypeByUrl(file),
        };
    }
    // File
    return {
        key: file.preview,
        name: file.name,
        size: file.size,
        path: file.path,
        type: file.type,
        preview: file.preview,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
    };
}
